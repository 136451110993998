<template>
  <div>
    <p v-show="errorServicio" class="text-center">
      <v-icon>mdi-alert-circle</v-icon>
      {{ errorServicio }}
    </p>

    <v-card :loading="loadingServicio" v-if="!errorServicio">
      <v-card-title>
        <v-img
          contain
          max-height="150"
          src="../../assets/parada-de-autobus.png"
        >
        </v-img>
      </v-card-title>

      <br />

      <h2 class="text-center">
        {{ servicio.nombre }}
      </h2>

      <br />

      <v-divider></v-divider>

      <v-card-title>
        Datos del servicio

        <EditarServicio
          :servicio="servicio"
          @edit="getServicio($route.params.id)"
        ></EditarServicio>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <h3>Nombre:</h3>
            <p>{{ servicio.nombre }}</p>

            <h3>Profesor:</h3>
            <p>{{ servicio.profesor }}</p>

            <h3>Observaciones:</h3>
            <p>{{ servicio.observaciones }}</p>

            <h3>Última modificación:</h3>
            <p>
              {{ new Date(servicio.ultimaModificacion).toLocaleString() }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <Precios></Precios>

      <v-divider></v-divider>

      <v-card-actions class="mt-4">
        <v-btn color="red" @click="eliminarServicio(servicio._id)">
          <v-icon>mdi-delete</v-icon>
          Eliminar servicio
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';
import EditarServicio from './EditarServicio';
import Precios from '../Precios/Precios';

export default {
  data() {
    return {
      servicio: {},
      errorServicio: null,
      loadingServicio: true,
    };
  },
  methods: {
    async getServicio(id) {
      try {
        this.loadingServicio = true;

        const res = await axios.get(process.env.VUE_APP_API_URL + '/servicios?id=' + id);

        this.servicio = res.data;

        this.loadingServicio = false;
      } catch (error) {
        this.loadingServicio = false;

        this.errorServicio = error.response.data;
      }
    },
    async eliminarServicio(id) {
      try {
        await axios.post(process.env.VUE_APP_API_URL + '/servicios/eliminar', {
          id,
        });
        this.$router.push({ name: 'Servicios' });
      } catch (error) {
        alert('Error - No se ha podido eliminar el servicio');
      }
    },
  },
  components: {
    EditarServicio,
    Precios,
  },
  mounted() {
    this.getServicio(this.$route.params.id);
  },
};
</script>
