<template>
  <v-dialog persistent v-model="toggleDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn fab x-small color="blue" class="ml-2" v-bind="attrs" v-on="on">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-card :loading="loading">
      <v-card-title>
        Nueva opción de precio
      </v-card-title>

      <v-card-text>
        <v-text-field
          label="Opción"
          v-model="nuevaOpcionPrecio.opcion"
        ></v-text-field>

        <v-text-field
          label="Precio"
          v-model="nuevaOpcionPrecio.precio"
        ></v-text-field>

        <v-select
          :items="cobros"
          label="Cobrado por"
          v-model="nuevaOpcionPrecio.cobro"
        ></v-select>
      </v-card-text>

      <v-card-actions>
        <v-btn color="blue" text @click="toggleDialog = false">
          Cancelar
        </v-btn>

        <v-btn color="blue" text @click="crear()">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NuevaOpcionPrecio',
  props: ['idServicio'],
  data() {
    return {
      toggleDialog: false,
      loading: false,
      cobros: [
        'hora',
        'día',
        'semana',
        'mes',
        'trimestre',
        'semestre',
        'año',
        'taller',
        'sesión',
      ],
      nuevaOpcionPrecio: {
        idServicio: '',
        opcion: '',
        precio: '',
        cobro: '',
      },
    };
  },
  methods: {
    async crear() {
      try {
        this.nuevaOpcionPrecio.idServicio = this.idServicio;

        this.loading = true;

        await axios.post(process.env.VUE_APP_API_URL + '/precios/nuevo', this.nuevaOpcionPrecio);

        this.toggleDialog = false;

        this.loading = false;

        this.$emit('edit');
      } catch (error) {
        this.loading = false;

        alert(error.response.data);
      }
    },
  },
};
</script>
