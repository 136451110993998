<template>
  <div>
    <v-card-title>
      Opciones de precios

      <NuevoPrecio
        :idServicio="$route.params.id"
        @edit="getTableData($route.params.id)"
      ></NuevoPrecio>
    </v-card-title>
    <v-card-text>
      <p v-show="errorTableData" class="text-center">
        <v-icon>mdi-alert-circle</v-icon>
        Error obteniendo los datos
      </p>

      <v-data-table
        v-if="!errorTableData"
        :headers="tableHeaders"
        :items="tableData"
        :loading="loadingTableData"
        loading-text="Cargando..."
      >
        <template v-slot:item.precio="{ item }"> {{ item.precio }} € </template>

        <template v-slot:item.cobro="{ item }"> Por {{ item.cobro }} </template>

        <template v-slot:item.acciones="{ item }">
          <v-icon color="red" @click="eliminarRow(item._id)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
import axios from 'axios';
import NuevoPrecio from './NuevoPrecio';

export default {
  data() {
    return {
      tableData: [],
      errorTableData: null,
      loadingTableData: true,
      tableHeaders: [
        {
          text: 'Opcion',
          value: 'opcion',
        },
        { text: 'Cobro', value: 'cobro', sortable: false },
        { text: 'Precio', value: 'precio', sortable: false },
        {
          text: 'Acciones',
          value: 'acciones',
          sortable: false,
          align: 'center',
        },
      ],
    };
  },
  methods: {
    async getTableData(idServicio) {
      try {
        this.loadingTableData = true;
        const res = await axios.get(process.env.VUE_APP_API_URL + '/precios?idServicio=' + idServicio);
        this.tableData = res.data;
        this.loadingTableData = false;
      } catch (error) {
        this.loadingTableData = false;
        this.errorTableData = error.response.data;
      }
    },
    async eliminarRow(id) {
      try {
        this.loadingTableData = true;
        await axios.post(process.env.VUE_APP_API_URL + '/precios/eliminar', {
          id,
        });
        this.getTableData(this.$route.params.id);
      } catch (error) {
        alert('Error - No se ha podido eliminar');
      }
    },
  },
  components: {
    NuevoPrecio,
  },
  mounted() {
    this.getTableData(this.$route.params.id);
  },
};
</script>
