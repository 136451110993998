<template>
  <v-dialog persistent v-model="toggleDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn fab x-small color="blue" class="ml-2" v-bind="attrs" v-on="on">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>

    <v-card :loading="loading">
      <v-card-title>
        Editar servicio
      </v-card-title>
      <v-card-text>
        <v-text-field label="Nombre" v-model="servicio.nombre"></v-text-field>

        <v-autocomplete
          v-model="servicio.profesor"
          :items="profesores"
          label="Profesor"
        ></v-autocomplete>

        <v-text-field
          label="Observaciones"
          v-model="servicio.observaciones"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue"
          text
          @click="
            toggleDialog = false;
            $emit('edit');
          "
        >
          Cancelar
        </v-btn>
        <v-btn color="blue" text @click="editar()">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EditarServicio',
  props: ['servicio'],
  data() {
    return {
      toggleDialog: false,
      loading: false,
      profesores: [],
    };
  },
  methods: {
    async editar() {
      try {
        this.loading = true;

        await axios.post(process.env.VUE_APP_API_URL + '/servicios/editar', {
          id: this.servicio._id,
          nombre: this.servicio.nombre,
          profesor: this.servicio.profesor,
          observaciones: this.servicio.observaciones,
        });

        this.loading = false;

        this.toggleDialog = false;
      } catch (error) {
        this.loading = false;

        alert(error.response.data);
      }
    },
    async getProfesores() {
      try {
        let profesores = await axios.get(process.env.VUE_APP_API_URL + '/profesores');
        profesores = profesores.data;

        for (let i = 0; i < profesores.length; i++) {
          this.profesores.push(
            `${profesores[i].nombre} ${profesores[i].primerApellido} ${profesores[i].segundoApellido}`
          );
        }
      } catch (error) {
        alert('Error - No se pueden obtener los profesores');

        this.toggleDialog = false;
      }
    },
  },
  mounted() {
    this.getProfesores();
  },
};
</script>
